<template>

    <section id="servicos" class="color--dark pt-5" ref="servicos">
        <div class="wrap">
            <b-row>
                <b-col class="col-md-12 col-lg-6 d-flex justify-content-center">
                    <div class="caption">
                        <h3>Serviços</h3>
                        <div class="p-md-5 mt-4 mt-md-0">
                            <picture>
                                <img src="@/assets/images/servicos.png" alt="" class="m-auto">
                            </picture>
                        </div>
                    </div>
                </b-col>
                <b-col v-if="blocos_servicos.length  > 0">
                    <ul class="mt-5">
                        <ItemServico v-for="(bloco, bloco_index) in blocos_servicos" :key="bloco_index" :bloco_servico="bloco"/>
                    </ul>
                </b-col>
            </b-row>
        </div>
    </section>

</template>
<script>
export default {
    components: {
        ItemServico: () => import("../components/ItemServico.vue"),
    },
    name: "Servicos",
    props: {
        texto_servicos: {},
        blocos_servicos: []
    },
    mounted() {
        console.log(this.blocos_servicos);
    }

}

</script>
<style scoped lang="scss">

@import "../assets/scss/global";

ul {
    display: flex;
    flex-flow: row wrap;

    li {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 20px;
        padding: 20px;
        border: 1px solid $cor1;
        width: 180px;
        height: 140px;
        cursor: default;
        transition: all .3s ease;
        box-shadow: 0 0px 0px 0px rgba($black, .0);

        .icon-color-1 {
            transition: transform .5s ease;
            font-size: 40px;
            margin-bottom: 5px;
        }

        p {
            transition: transform .5s ease;
        }

        &:hover {
            box-shadow: 0 5px 15px 0px rgba($cor1, .2);

            .icon-color-1 {
                transform: scale(1.3);
            }

            p {
                transition-delay: .2s;
                transform: translateY(5px);
            }
        }
    }
}

@media (max-width: $mobile) {
    ul {
        justify-content: center;
    }
}

@media (max-width: $screenSD) {
    ul {

        li {
            margin: 26px 5px;
            width: 140px;
            height: 110px;

            p {
                line-height: 21px;
            }
        }
    }
}


</style>
